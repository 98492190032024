import { NavLink, useLocation } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import Button from "../UI/Button/SimpleButton";
import { adminActions } from "../../store/admin-slice";
import { customerActions } from "../../store/customer-slice";

const MainNavigation = () => {
  const isAdminLoggedIn = useSelector((state) => state.admin.isLoggedIn);
  const dispatch = useDispatch();

  const onAdminLogOutHandler = () => {
    dispatch(adminActions.logout());
  };

  const onClientLogOutHandler = () => {
    dispatch(customerActions.logout());
  };

  const location = useLocation();

  //#region UI

  if (location.pathname === "/admin-login") {
    return <div></div>;
  }
  if (location.pathname === "/autentificare") {
    return "";
  }

  const adminMenu = (
    <header className={classes.header}>
      <div className={classes.logo}>RoboSer</div>
      <nav className={classes.nav}>
        <ul>
          <li>
            <NavLink to="/client-nou" activeClassName={classes.active}>
              Adauga Client
            </NavLink>
          </li>

          <li>
            <NavLink to="/clienti" activeClassName={classes.active}>
              Clienti
            </NavLink>
          </li>
        </ul>
      </nav>
      <Button
        size="sm"
        variant="outline-primary"
        type="submit"
        onClick={onAdminLogOutHandler}
      >
        Deconectare
      </Button>
    </header>
  );

  const customerMenu = (
    <header className={classes.header}>
      <div className={classes.logo}>RoboSer</div>
      <nav className={classes.nav}>
        <ul>
          <li>
            <NavLink to="/dashboard" activeClassName={classes.active}>
              Dashboard
            </NavLink>
          </li>

          <li>
            <NavLink to="/sere" activeClassName={classes.active}>
              Sere
            </NavLink>
          </li>
        </ul>
      </nav>
      <Button
        size="sm"
        variant="outline-primary"
        type="submit"
        onClick={onClientLogOutHandler}
      >
        Deconectare
      </Button>
    </header>
  );

  //#endregion

  return (
    <Fragment>
      {isAdminLoggedIn && adminMenu}
      {!isAdminLoggedIn && customerMenu}
    </Fragment>
  );
};

export default MainNavigation;
