import { createSlice } from "@reduxjs/toolkit";

const mqttInitialState = {
  mqttClient: null,
  connectionStatus: { status: "Se conecteaza la server", isConnected: false },
};

const mqttSlice = createSlice({
  name: "mqttSlice",
  initialState: mqttInitialState,
  reducers: {
    setMqttConnection(state, action) {
      if (state.mqttClient === null) {
        state.mqttClient = action.payload;
      }
    },
    setConnectionStatus(state, action) {
      state.connectionStatus = action.payload.connectionStatus;
    },
  },
});

export const mqttActions = mqttSlice.actions;
export default mqttSlice.reducer;
