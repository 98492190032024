import { configureStore } from "@reduxjs/toolkit";
import AdminReducer from "./admin-slice";
import AdminCustomerReducer from "./admin-customer-slice";
import CustomerReducer from "./customer-slice";
import GreenhouseReducer from "./greenhouse-slice";
import MqttReducer from "./mqtt-slice";

const store = configureStore({
  reducer: {
    admin: AdminReducer,
    adminCustomer: AdminCustomerReducer,
    customer: CustomerReducer,
    greenhouse: GreenhouseReducer,
    mqtt: MqttReducer,
  },
});

export default store;
