import { createSlice } from "@reduxjs/toolkit";

const customerInitialState = {
  token: localStorage.getItem("cautkn"), // token
  expirationTime: localStorage.getItem("excautkn"), //expiration time for token
  userId: localStorage.getItem("userId"),
  isLoggedIn: localStorage.getItem("isLoggedIn"),
};

const customerSlice = createSlice({
  name: "customer",
  initialState: customerInitialState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.expirationTime = action.payload.expirationTime;
      state.userId = action.payload.userId;
      state.isLoggedIn = !!state.token;

      localStorage.setItem("cautkn", state.token);
      localStorage.setItem("isLoggedIn", state.isLoggedIn);
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("excautkn", state.expirationTime);
    },
    logout(state) {
      state.token = null;
      state.isLoggedIn = false;

      localStorage.removeItem("cautkn");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("excautkn");
      localStorage.removeItem("userId");
    },
  },
});

export const customerActions = customerSlice.actions;
export default customerSlice.reducer;
