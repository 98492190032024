import { createSlice } from "@reduxjs/toolkit";

const adminInitialState = {
  token: localStorage.getItem("token_admin"),
  expirationTime: localStorage.getItem("expiration_time_admin"),
  userId: localStorage.getItem("userId_admin"),
  isLoggedIn: localStorage.getItem("isLoggedIn_admin"),
};

const adminSlice = createSlice({
  name: "admin",
  initialState: adminInitialState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.expirationTime = action.payload.expirationTime;
      state.userId = action.payload.userId;
      state.isLoggedIn = !!state.token;

      localStorage.setItem("token_admin", state.token);
      localStorage.setItem("isLoggedIn_admin", state.isLoggedIn);
      localStorage.setItem("userId_admin", state.userId);
      localStorage.setItem("expirationTime_admin", state.expirationTime);
    },
    logout(state, action) {
      state.token = null;
      state.isLoggedIn = false;

      localStorage.removeItem("token_admin");
      localStorage.removeItem("isLoggedIn_admin");
      localStorage.removeItem("expirationTime_admin");
      localStorage.removeItem("userId_admin");
    },
  },
});

export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
