import { createSlice } from "@reduxjs/toolkit";

const greenhouseInitialState = {
  greenhouses: null,
  singleGreenHouse: null,
  modulesOfSingleGreenHouse: [], // kits
};

const greenhouseSlice = createSlice({
  name: "greenhouse",
  initialState: greenhouseInitialState,
  reducers: {
    setGreenHouses(state, action) {
      state.greenhouses = action.payload;
    },
    setSingleGreenHouse(state, action) {
      //SEARCH FOR GREEN HOUSE IN STATE, OTHERWISE REQUEST FROM API
      let foundedGreenHouse = null;

      if (state.greenhouses !== null) {
        const idGreenHouse = action.payload;

        foundedGreenHouse = state.greenhouses.greenhouses.find((greenhouse) => {
          let greenHouse = null;
          if (greenhouse.id === parseInt(idGreenHouse)) {
            greenHouse = greenhouse;
          }
          return greenHouse;
        });
        const setGreenHouse = {
          id: foundedGreenHouse.id,
          name: foundedGreenHouse.name,
        };

        state.singleGreenHouse = setGreenHouse;

        localStorage.setItem("singleGreenHouse", JSON.stringify(setGreenHouse));
      } else {
        const greenHouse = JSON.parse(localStorage.getItem("singleGreenHouse"));
        state.singleGreenHouse = greenHouse;
      }
    },
    //Set modules of a single GreenHouse
    setModules(state, action) {
      // we set all the modules of green house
      state.modulesOfSingleGreenHouse = action.payload;
    },
  },
});

export const greenHouseActions = greenhouseSlice.actions;
export default greenhouseSlice.reducer;
